import React, { useContext } from 'react';

import { AuthContext } from 'components/Auth/Auth';

import PleaseLogin from './PleaseLogin';

const Login = ({ children }) => {
  const { currentUser } = useContext(AuthContext);

  return !!currentUser ? children : <PleaseLogin />;
};

export default Login;
