import React, { useEffect, useContext, useState, useMemo } from 'react';
import { functions } from 'backend.js';
import { httpsCallable } from 'firebase/functions';

import css from './Personal.module.scss';

import WizardNavigator from 'components/WizardNavigator/WizardNavigator';

import { AuthContext } from 'components/Auth/Auth';

import Loader from 'components/Loader/Loader';
import { size } from 'lodash';

const stages = ['קליטת הספר', 'ממתין לאישור תשלום', 'בבדיקה', 'צפייה בתוצאות'];

const getSubmissions = httpsCallable(functions, 'getSubmissions');

const Personal = () => {
  const { currentUser } = useContext(AuthContext);

  const [submissionsData, setSubmissionData] = useState();

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    getSubmissions({ userUID: currentUser.uid }).then((result) => {
      setSubmissionData(result.data.result.submissions);
      setIsFetching(false);
    });
  }, [currentUser]);

  const currentStatus = useMemo(() => {
    if (!submissionsData || !submissionsData.status) {
      return 0;
    }
    if (submissionsData.status === 'submitted') {
      return 1;
    }
    if (submissionsData.status === 'check') {
      return 2;
    }
    if (submissionsData.status === 'done') {
      return 3;
    }
  }, [submissionsData]);

  return (
    <div className={css['container']}>
      {/* <div className={css['title']}>הספרים שלי</div> */}
      <Loader isFetching={isFetching}>
        {submissionsData && (
          <div className={css['book-container']}>
            {size(submissionsData) > 0 ? (
              <>
                <div className={css['name']}>{submissionsData[0].bookName}</div>
                <div className={css['status']}>
                  <WizardNavigator
                    steps={stages}
                    verticalSteps={true}
                    reverseSteps={true}
                    onChangeStep={() => {}}
                    currentStep={currentStatus}
                  />
                </div>
              </>
            ) : (
              <div className={css['no-books-msg']}>עוד לא הגשת ספרים</div>
            )}
          </div>
        )}
      </Loader>
    </div>
  );
};

export default Personal;
