import React from 'react';

import css from './Header.module.scss';

import { Logo } from 'components/Logo/Logo';
import { NavigationPane } from 'components/NavigationPane/NavigationPane.js';
import UserMenu from 'components/UserMenu/UserMenu';

export const Header = ({ location }) => {
  return (
    <div className={css['header-container']}>
      <Logo />
      {location.pathname !== '/writer-wizard' && (
        <div className={css['nav-pane-container']}>
          <NavigationPane
            options={[
              { title: 'הוצאות ספרים', path: '/publishers' },
              {
                title: 'סופרים/ות',
                path: '/writers',
                additionalPaths: ['/writer-wizard'],
              },
            ]}
            location={location}
          />
        </div>
      )}
      <div id="wizard-steps" className={css['wizard-steps']}></div>
      <UserMenu />
    </div>
  );
};

export default Header;
