import { useMemo } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import find from 'lodash/fp/find';

export const useValidations = (validationProps = {}, state, path, type) => {
  const { isRequired, regex } = validationProps;

  const value = useMemo(() => state[path], [state, path]);

  const isRequiredValidationStatus = useMemo(() => {
    if (isRequired) {
      if (type === 'file' && value) {
        return { isValid: true };
      }
      if (!isEmpty(value)) {
        return { isValid: true };
      }
      return { isValid: false, message: 'שדה חובה' };
    }

    return { isValid: true };
  }, [value, type, isRequired]);

  const regexValidationStatus = useMemo(() => {
    if (regex) {
      if (regex.value.test(value)) {
        return { isValid: true };
      }
      return { isValid: false, message: regex.invalidMessage };
    }

    return { isValid: true };
  }, [value, regex]);

  return (
    find(
      ({ isValid }) => isValid === false,
      [isRequiredValidationStatus, regexValidationStatus]
    ) || { isValid: true }
  );
};
