import React from 'react';

import css from './Textarea.module.scss';

const Textarea = ({ value, onChange }) => {
  return (
    <textarea
      value={value}
      onChange={onChange}
      className={css['field-textarea']}
    />
  );
};

export default Textarea;
