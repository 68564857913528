import React from 'react';
import { Link } from 'react-router-dom';

import css from './Logo.module.scss';

export const Logo = () => {
  return (
    <Link to="/" style={{ textDecoration: 'none' }}>
      <div className={css['logo-container']}>
        <div className={css['su-title']}>su</div>
        <div className={css['book-title']}>Book</div>
      </div>
    </Link>
  );
};

export default Logo;
