import React, { createContext, useCallback, useState, useContext } from 'react';
import set from 'lodash/fp/set';

import css from './WriterWizard.module.scss';

import { navigateToPath } from 'utils/history.utils';

import Wizard from 'components/Wizard/Wizard';
import Step from 'components/Step/Step';
import PublishersSelector from 'components/PublishersSelector/PublishersSelector';
import PersonalDetails from 'components/PersonalDetails/PersonalDetails';
import BookUpload from 'components/BookUpload/BookUpload';

import { ref, uploadBytes } from 'firebase/storage';
import { AuthContext } from 'components/Auth/Auth';

import { storage } from 'backend.js';
import Payment from 'components/Payment/Payment';

const initialWritedWizardState = {};

const WriterWizardContext = createContext(null);

const WriterWizard = () => {
  const [writerWizardState, setWriterWizardState] = useState(
    initialWritedWizardState
  );

  const { currentUser } = useContext(AuthContext);

  const sendData = (file, inputType) => {
    if (currentUser && file) {
      const storageRef = ref(
        storage,
        `uploads/${inputType}/${file.name}_${currentUser.uid}`
      );
      try {
        uploadBytes(storageRef, file);
      } catch (error) {
        alert(error);
      }
    }
  };

  const onChangePublisher = useCallback(
    (data) => setWriterWizardState(set('publisher', data, writerWizardState)),
    [writerWizardState]
  );

  const onChangePersonalDetails = useCallback(
    (data) =>
      setWriterWizardState(set('personalDetails', data, writerWizardState)),
    [writerWizardState]
  );

  const onChangeBook = useCallback(
    (data) => setWriterWizardState(set('book', data, writerWizardState)),
    [writerWizardState]
  );

  return (
    <div className={css['wizard-container']}>
      <WriterWizardContext.Provider value={{}}>
        <Wizard
          onFinish={() => {}}
          onExit={() => {
            navigateToPath('/');
          }}
          verticalSteps={true}
          stepsContainerId="wizard-steps"
        >
          <Step
            name="הוצאות לאור"
            onChange={onChangePublisher}
            state={writerWizardState['publisher']}
            component={PublishersSelector}
          />
          <Step
            name="פרטים אישיים"
            onChange={onChangePersonalDetails}
            state={writerWizardState['personalDetails']}
            sendData={sendData}
            component={PersonalDetails}
          />
          <Step
            name="העלאת כתב היד"
            onChange={onChangeBook}
            state={writerWizardState['book']}
            sendData={sendData}
            component={BookUpload}
          />
          <Step
            name="תשלום"
            state={writerWizardState}
            component={Payment}
            sendData={sendData}
          />
        </Wizard>
      </WriterWizardContext.Provider>
    </div>
  );
};

export default WriterWizard;
