import React, {
  cloneElement,
  Children,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';
import size from 'lodash/fp/size';
import classNames from 'classnames/bind';

import css from './Wizard.module.scss';

import WizardNavigator from 'components/WizardNavigator/WizardNavigator';

const cx = classNames.bind(css);

const Wizard = ({
  children,
  onFinish,
  onExit,
  verticalSteps = false,
  stepsContainerId,
  ...rest
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const [isDomReady, setIsDomReady] = React.useState(false);

  useEffect(() => {
    if (stepsContainerId) {
      setIsDomReady(true);
    }
  }, [stepsContainerId]);

  const childrenLength = size(children);

  const childrenArray = useMemo(
    () =>
      Children.toArray(children).map((child, i) =>
        cloneElement(child, {
          onComplete:
            childrenLength - 1 === i
              ? onFinish
              : () => {
                  const nextStep = i + 1;
                  setCurrentStep(nextStep);
                },
          onCancel: i === 0 ? onExit : () => setCurrentStep(i > 0 ? i - 1 : 0),
          ...rest,
        })
      ),
    [children, onFinish, childrenLength, onExit, rest]
  );

  const renderSteps = () => {
    const wizardNavigator = (
      <WizardNavigator
        onChangeStep={(step) => step <= currentStep && setCurrentStep(step)}
        steps={childrenArray.map(({ props }) => props.name)}
        currentStep={currentStep}
        verticalSteps={verticalSteps}
      />
    );
    if (
      isDomReady &&
      stepsContainerId &&
      document.getElementById(stepsContainerId)
    ) {
      return createPortal(
        wizardNavigator,
        document.getElementById(stepsContainerId)
      );
    }
    return <div className={css['navigator-container']}>{wizardNavigator}</div>;
  };

  return (
    <div className={css['container']}>
      {renderSteps()}
      <div
        className={cx('wizard-content-container', {
          'vertical-steps': verticalSteps,
        })}
      >
        {childrenArray[currentStep]}
      </div>
    </div>
  );
};

export default Wizard;
