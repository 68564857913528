import React, {
  useCallback,
  useMemo,
  useContext,
  useEffect,
  useState,
} from 'react';
import set from 'lodash/fp/set';

import css from './FormField.module.scss';

import { FormContext } from '../Form/Form';

import { useValidations } from './formField.hooks';

import Input from 'components/Input/Input';
import RadioGroup from 'components/RadioGroup/RadioGroup';
import FileUploadField from 'components/FileUploadField/FileUploadField';
import Textarea from 'components/Textarea/Textarea';
import RadioButton from 'components/RadioButton/RadioButton';

const COMPONENTS_MAP = {
  text: Input,
  number: Input,
  radio: RadioGroup,
  file: FileUploadField,
  textarea: Textarea,
  radioButton: RadioButton,
};

const ON_CHANGE_FUNCTIONS_MAP = {
  text: (fieldKey, data, state, onChange) => {
    onChange(set(fieldKey, data.target.value, state));
  },
  number: (fieldKey, data, state, onChange) => {
    const validatedValue = data.target.value.replace(/[^0-9]/g, '');
    onChange(set(fieldKey, validatedValue, state));
  },
  radio: (fieldKey, data, state, onChange) => {
    onChange(set(fieldKey, data.target.value, state));
  },
  file: (fieldKey, data, state, onChange) => {
    onChange(set(fieldKey, data, state));
  },
  textarea: (fieldKey, data, state, onChange) => {
    onChange(set(fieldKey, data.target.value, state));
  },
  radioButton: (fieldKey, data, state, onChange) => {
    onChange(set(fieldKey, data, state));
  },
};

const FormField = ({ label, path, type, validationProps, ...restProps }) => {
  const [shouldIgnoreShowValidation, setShouldIgnoreShowValidation] =
    useState(false);

  const {
    state = {},
    onChange,
    shouldValidate: shouldShowValidation,
    setFieldValidationState,
  } = useContext(FormContext);

  const validationStatus = useValidations(validationProps, state, path, type);

  useEffect(() => {
    setFieldValidationState(path, validationStatus.isValid);
    return () => setFieldValidationState(path, true);
  }, [validationStatus, path, setFieldValidationState]);

  const onChangeWrapper = useCallback(
    (data) => ON_CHANGE_FUNCTIONS_MAP[type](path, data, state, onChange),
    [path, type, state, onChange]
  );

  const FieldComponent = useMemo(() => COMPONENTS_MAP[type], [type]);

  return (
    <div className={css['field-container']}>
      <div className={css['field-label']}>{label}</div>
      <FieldComponent
        {...restProps}
        type={type}
        value={state[path]}
        name={path}
        onChange={onChangeWrapper}
        onTurnOffValidation={() => setShouldIgnoreShowValidation(true)}
        onTurnOnValidation={() => setShouldIgnoreShowValidation(false)}
      />
      {validationStatus.isValid === false &&
        shouldIgnoreShowValidation === false &&
        shouldShowValidation && (
          <div className={css['warning-msg']}>{validationStatus.message}</div>
        )}
    </div>
  );
};

export default FormField;
