import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';

import history from 'utils/history.utils';

import Header from 'components/Header/Header';
import { AuthProvider } from 'components/Auth/Auth';
import Home from 'components/Home/Home';
import Personal from 'components/Personal/Personal';
import WriterWizard from 'components/WriterWizard/WriterWizard';
import FAQ from 'components/FAQ/FAQ';
import Contact from 'components/Contact/Contact';
import Policy from 'components/Policy/Policy';
import PublishersSection from 'components/PublishersSection/PublishersSection';

export const Routes = () => (
  <AuthProvider>
    <Router history={history}>
      <Route path="/:path?" component={Header}></Route>
      <Switch>
        <Route exact path="/">
          <Redirect to="/writers" />
        </Route>
        <Route exact path="/writers" component={Home} />
        <Route exact path="/personal" component={Personal} />
        <Route exact path="/writer-wizard" component={WriterWizard} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/policy" component={Policy} />
        <Route exact path="/publishers" component={PublishersSection} />
      </Switch>
    </Router>
  </AuthProvider>
);

export default Routes;
