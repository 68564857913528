import React, { useMemo } from 'react';

import css from './FileUploadField.module.scss';

import { ReactComponent as TrashIcon } from 'assets/images/trash.svg';

const FileUploadField = ({ value, onChange, renderAddtionalInfo }) => {
  const changeHandler = (event) => {
    onChange(event.target.files[0]);
  };

  const clearSelectedFile = () => {
    onChange();
  };

  const fileUrl = useMemo(() => {
    return value ? URL.createObjectURL(value) : '';
  }, [value]);

  return value ? (
    <div className={css['file-container']}>
      <div className={css['file-name-label']}>{value.name}</div>
      <embed
        src={fileUrl}
        className={css['file-preview']}
        width={220}
        height={220}
      />
      {renderAddtionalInfo && (
        <div className={css['addtional-info']}>{renderAddtionalInfo()}</div>
      )}
      <div
        className={css['delete-button-container']}
        onClick={clearSelectedFile}
      >
        <TrashIcon />
      </div>
    </div>
  ) : (
    <label className={css['file-input-container']}>
      <input
        value={value}
        className={css['file-input']}
        type="file"
        name="file"
        accept="application/pdf"
        onChange={changeHandler}
      />
      העלאת קובץ
    </label>
  );
};

export default FileUploadField;
