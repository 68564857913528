import React from 'react';
import classNames from 'classnames/bind';

import css from './InfoIcon.module.scss';

const cx = classNames.bind(css);

const InfoIcon = ({ className }) => {
  const containerClassNames = cx('container', { [className]: className });

  return <div className={containerClassNames}>i</div>;
};

export default InfoIcon;
