import React from 'react';

import css from './PleaseLogin.module.scss';

import { signInWithGoogle } from 'utils/auth.utils';

import { ReactComponent as GoogleIcon } from 'assets/images/google.svg';
import Title from 'components/Title/Title';
import Button from 'components/Button/Button';

const PleaseLogin = () => {
  return (
    <div className={css['login-required-container']}>
      <Title>בכדי לסיים את התהליך עלייך להתחבר</Title>
      <Button
        label={
          <div className={css['button-content']}>
            <div className={css['button-label']}>התחבר באמצעות גוגל</div>
            <GoogleIcon />
          </div>
        }
        onClick={signInWithGoogle}
        className={css['signin-with-google-button']}
      />
    </div>
  );
};

export default PleaseLogin;
