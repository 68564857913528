import React from 'react';
import classNames from 'classnames/bind';
import toLower from 'lodash/fp/toLower';

import css from './Button.module.scss';

import { SUBMIT } from './button.constants';

const cx = classNames.bind(css);

const Button = ({
  label,
  onClick,
  className,
  type = SUBMIT,
  isDisabled = false,
}) => {
  const buttonClassNames = cx('button', className, {
    [toLower(type)]: true,
    'button-disabled': isDisabled,
  });

  return (
    <div onClick={onClick} className={buttonClassNames}>
      {label}
    </div>
  );
};

export default Button;
