import React, { useState, useCallback } from 'react';

import css from './PersonalDetails.module.scss';

import { GHOST } from 'components/Button/button.constants';

import Form from 'components/Form/Form';
import FormField from 'components/FormField/FormField';
import Button from 'components/Button/Button';
import Title from 'components/Title/Title';

const AGE_OPTIONS = [
  { value: 'true', label: 'כן' },
  { value: 'false', label: 'לא' },
];

const PersonalDetails = ({
  onComplete,
  onCancel,
  state = {},
  onChange,
  sendData,
}) => {
  const [isValid, setIsValid] = useState(true);
  const [shouldValidate, setShouldValidate] = useState(false);

  const onNext = useCallback(() => {
    setShouldValidate(true);
    if (isValid) {
      sendData(state.biography, 'biography');
      onComplete();
    }
  }, [onComplete, isValid, sendData, state.biography]);

  return (
    <div className={css['container']}>
      <div className={css['right-panel']}>
        <Button onClick={onCancel} type={GHOST} label="חזרה" />
      </div>
      <div className={css['content']}>
        <Title>אנא מלאו את השדות הבאים:</Title>
        <Form
          state={state}
          onChange={onChange}
          shouldValidate={shouldValidate}
          onValidationStatusChanged={setIsValid}
        >
          <div className={css['fields-group']}>
            <FormField
              label="שם פרטי:"
              type="text"
              path="firstName"
              validationProps={{ isRequired: true }}
            />
            <FormField
              label="שם משפחה:"
              type="text"
              path="lastName"
              validationProps={{ isRequired: true }}
            />
          </div>
          <div className={css['fields-group']}>
            <FormField
              label="כתובת:"
              type="text"
              path="address"
              validationProps={{ isRequired: true }}
            />
            <FormField
              label="דוא״ל:"
              type="text"
              path="email"
              validationProps={{
                isRequired: true,
                // regex: {
                //   value:
                //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                //   invalidMessage: 'כתובת לא תקינה',
                // },
              }}
            />
          </div>
          <div className={css['fields-group']}>
            <FormField
              label="טלפון:"
              type="number"
              path="phone"
              validationProps={{ isRequired: true }}
            />
            <div>
              <FormField
                label="האם המחבר מעל גיל 17:"
                type="radioButton"
                path="isAbove17"
                options={AGE_OPTIONS}
                validationProps={{ isRequired: true }}
              />
              {state['isAbove17'] === 'false' ? (
                <FormField
                  label="אישור הורים:"
                  type="file"
                  path="parentsAgreement"
                  validationProps={{ isRequired: true }}
                />
              ) : null}
            </div>
          </div>
          <div className={css['fields-group']}>
            <FormField
              label="ביוגרפיה של המחבר/ת:"
              type="file"
              path="biography"
            />
          </div>
        </Form>
      </div>
      <div className={css['right-panel']}>
        <Button onClick={onNext} label="הבא" />
      </div>
    </div>
  );
};

export default PersonalDetails;
