import React from 'react';
import classNames from 'classnames/bind';

import css from './WizardStep.module.scss';

import { ReactComponent as OkIcon } from 'assets/images/checked-svgrepo-com.svg';

const cx = classNames.bind(css);

const WizardStep = ({ number, label, status, onChangeStep, verticalSteps }) => {
  const classNames = cx('container', {
    [status]: Boolean(status),
    'vertical-steps': verticalSteps,
  });

  return (
    <div onClick={onChangeStep} className={classNames}>
      <div className={css['label']}>{label}</div>
      <div className={css['step-number']}>
        {status === 'PASSED' ? <OkIcon /> : number}
      </div>
    </div>
  );
};

export default WizardStep;
