import React, { useState, useCallback, useEffect, useRef } from 'react';
import size from 'lodash/fp/size';
import set from 'lodash/fp/set';
import { PDFDocument } from 'pdf-lib';

import css from './BookUpload.module.scss';

import { GHOST } from 'components/Button/button.constants';

import Button from 'components/Button/Button';
import Form from 'components/Form/Form';
import FormField from 'components/FormField/FormField';
import Title from 'components/Title/Title';

const BOOK_TYPE_OPTIONS = [
  {
    label: 'סיפורת',
    description: 'קובץ סיפורים קצרים, נובלה או רומן',
    value: 'story',
  },
  { label: 'שירה', value: 'poetry' },
  { label: 'עיון', value: 'info' },
  { label: 'ספרות ילדים', value: 'childrenLitrature' },
];

const BookUpload = ({
  onComplete,
  onCancel,
  state = {},
  onChange,
  sendData,
}) => {
  const [isValid, setIsValid] = useState(true);
  const [shouldValidate, setShouldValidate] = useState(false);
  const currentState = useRef();

  useEffect(() => {
    currentState.current = state;
  }, [state]);

  const onNext = useCallback(() => {
    setShouldValidate(true);

    if (isValid) {
      sendData(state.book, 'book');
      onComplete();
    }
  }, [onComplete, isValid, sendData, state.book]);

  useEffect(() => {
    const updateNumOfPages = async () => {
      if (state.book) {
        const readFile = (file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);

            reader.readAsArrayBuffer(file);
          });
        };

        const getNumPages = async (file) => {
          const arrayBuffer = await readFile(file);

          const pdf = await PDFDocument.load(arrayBuffer);

          return pdf.getPages();
        };

        onChange(
          set(
            'numOfPages',
            size(await getNumPages(state.book)),
            currentState.current
          )
        );
      }
    };
    updateNumOfPages();
  }, [state.book, onChange]);

  return (
    <div className={css['container']}>
      <div className={css['left-panel']}>
        <Button onClick={onCancel} type={GHOST} label="קודם" />
      </div>
      <div className={css['content']}>
        <Title>אנא מלאו את השדות הבאים:</Title>
        <Form
          state={state}
          onChange={onChange}
          shouldValidate={shouldValidate}
          onValidationStatusChanged={setIsValid}
        >
          <div className={css['fields-group']}>
            <FormField
              label="שם הספר:"
              type="text"
              path="bookName"
              validationProps={{ isRequired: true }}
            />
          </div>
          <div className={css['fields-group']}>
            <FormField
              label="סוגה:"
              type="radioButton"
              options={BOOK_TYPE_OPTIONS}
              path="bookType"
              validationProps={{ isRequired: true }}
            />
          </div>
          <div className={css['fields-group']}>
            <FormField
              label="כתב היד:"
              type="file"
              path="book"
              validationProps={{ isRequired: true }}
              renderAddtionalInfo={() => (
                <>
                  <div className={css['num-of-pages']}>
                    {state['numOfPages']}
                  </div>
                  <div className={css['description']}>עמודים</div>
                </>
              )}
            />
          </div>
          <div className={css['fields-group']}>
            <FormField
              label="תיאור של כתב היד והמלצות:"
              type="textarea"
              path="description"
            />
          </div>
        </Form>
      </div>
      <div className={css['right-panel']}>
        <Button onClick={onNext} label="הבא" />
      </div>
    </div>
  );
};

export default BookUpload;
