import React, { useMemo } from 'react';

import css from './RadioGroup.module.scss';

const RadioGroup = ({ options = [], value: selectedValue, onChange, name }) => {
  const radioButtons = useMemo(() => {
    return options.map(({ label, value }) => (
      <div key={value} className={css['radio-option-container']}>
        <input
          type="radio"
          value={value}
          name={name}
          defaultChecked={selectedValue === value}
          className={css['radio-option']}
        />
        <div className={css['radio-label']}>{label}</div>
      </div>
    ));
  }, [options, name, selectedValue]);

  return <div onChange={onChange}>{radioButtons}</div>;
};

export default RadioGroup;
