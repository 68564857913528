import React from 'react';

import css from './Input.module.scss';

const Input = ({
  value,
  onChange,
  type = 'text',
  onTurnOnValidation,
  onTurnOffValidation,
}) => {
  return (
    <input
      type={type}
      value={value}
      className={css['input']}
      onBlur={onTurnOnValidation}
      onFocus={onTurnOffValidation}
      onChange={onChange}
    />
  );
};

export default Input;
