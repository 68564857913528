import React from 'react';

import css from './PaymentSummary.module.scss';

const PaymentSummary = ({ sum }) => {
  return sum ? (
    <div className={css['container']}>
      <div>סה״כ לתשלום:</div>
      <div className={css['price']}>{sum}</div>
    </div>
  ) : null;
};

export default PaymentSummary;
