import React from 'react';
import classNames from 'classnames/bind';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import css from './Publisher.module.scss';

const cx = classNames.bind(css);

const PUBLISHERS_TYPES = {
  TRADITIONAL: 'הוצאה מסורתית',
  MODERN: 'הוצאה מודרנית',
};

const Publisher = ({
  id,
  label,
  type,
  communicationModel,
  booksType,
  timeToTreat,
  url,
  pricing,
  isSelected,
  onSelect,
  imgUrl,
}) => {
  const renderPrice = (pricing) => {
    return (
      <>
        <div className={cx('publisher-details', 'pricing')}>
          <div className={css['price']}>{pricing[0].price}</div>
          <div className={css['description']}>
            עד {pricing[0].maxAmountOfPages} עמודים
          </div>
        </div>
        <div className={cx('publisher-details', 'pricing')}>
          <div className={css['price']}>{pricing[1].price}</div>
          <div className={css['description']}>
            מעל {pricing[1].minAmountOfPages - 1} עמודים
          </div>
        </div>
      </>
    );
  };

  return (
    <div key={id} className={css['publisher-container']}>
      <img className={css['logo']} src={imgUrl} alt="logo" />
      <div
        onClick={onSelect}
        className={cx('publisher', {
          'select-publisher': isSelected,
        })}
      >
        <div className={css['details']}>
          <div className={css['publisher-label']}>{label}</div>
          <div className={cx('type-label', type)}>{PUBLISHERS_TYPES[type]}</div>
          <div className={css['site']}>
            <a target="_blank" rel="noopener noreferrer" href={url}>
              לאתר
            </a>
          </div>
          <div className={css['additional-details-titles']}>
            <div className={css['small-description']}>סוג מימון</div>
            <div className={css['small-description']}>כתבי יד בתחום</div>
            <div className={css['small-description']}>זמן טיפול משוער</div>
          </div>
          <div className={css['additional-details']}>
            <div className={cx('publisher-details', 'communication-model')}>
              {communicationModel}
            </div>
            <div className={cx('publisher-details', 'books-type')}>
              {booksType}
            </div>
            <div className={cx('publisher-details', 'time-to-treat')}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {timeToTreat ? timeToTreat.value : 'unknown'}
                <div className={css['description']}>
                  {timeToTreat ? timeToTreat.timeUnit.label : 'no description'}
                </div>
              </div>
            </div>
          </div>
          <div className={css['additional-details-titles']}>
            <div className={css['medium-description']}>עלות בחינת כתב היד</div>
          </div>
          <div className={cx('additional-details', 'pricing-details')}>
            {pricing ? renderPrice(pricing) : <div></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publisher;
