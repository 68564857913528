import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import includes from 'lodash/fp/includes';

import css from './NavigationPane.module.scss';

const cx = classNames.bind(css);

export const NavigationPane = ({ options, location = {} }) => {
  return (
    <div className={css['container']}>
      {options.map(({ title, path, additionalPaths }) => {
        const isSelected =
          location.pathname === path ||
          includes(location.pathname, additionalPaths);
        const navClassName = cx('nav-option', {
          'selected-nav-option': isSelected,
        });
        return (
          <Link key={path} to={path} style={{ textDecoration: 'none' }}>
            <div className={navClassName}>{title}</div>
          </Link>
        );
      })}
    </div>
  );
};

export default NavigationPane;
