import React, { Fragment } from 'react';
import classNames from 'classnames/bind';

import css from './WizardNavigator.module.scss';

import WizardStep from 'components/WizardStep/WizardStep';

const cx = classNames.bind(css);

const getStepStatus = (stepIndex, currentStep) => {
  if (stepIndex === currentStep) return 'SELECTED';
  else if (currentStep > stepIndex) return 'PASSED';
  else return 'NONE';
};

const WizardNavigator = ({
  steps = [],
  verticalSteps = false,
  reverseSteps = false,
  onChangeStep,
  currentStep,
}) => (
  <div
    className={cx('wizard-container', {
      'vertical-steps': verticalSteps,
      'reverse-steps': reverseSteps,
    })}
  >
    {steps.map((step, i) => (
      <Fragment key={`${step}_${i}`}>
        <WizardStep
          status={getStepStatus(i, currentStep)}
          onChangeStep={() => onChangeStep(i)}
          number={i + 1}
          label={step}
          verticalSteps={verticalSteps}
        />
        {verticalSteps && i < steps.length - 1 && (
          <div className={cx('progress-bar')}>
            {currentStep > i && (
              <div className={css['completed-progress-bar']} />
            )}
          </div>
        )}
      </Fragment>
    ))}
  </div>
);

export default WizardNavigator;
