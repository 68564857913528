import React from 'react';
import classNames from 'classnames/bind';

import css from './Loader.module.scss';

const cx = classNames.bind(css);

export const Loader = ({
  children = null,
  isFetching,
  shouldRenderInTheBackground = false,
  label,
}) => {
  if (shouldRenderInTheBackground) {
    return (
      <>
        <div className={cx('loader-container', { hidden: !isFetching })}>
          <div className={css['loader']} />
        </div>
        <div className={cx({ hidden: isFetching })}>{children}</div>
      </>
    );
  }

  return isFetching ? (
    <div className={cx('loader-container', { 'flex-column': label })}>
      <div className={css['loader']} />
      {label && <div className={css['label']}>{label}</div>}
    </div>
  ) : (
    children
  );
};

export default Loader;
