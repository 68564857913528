import React, { useCallback, useState, useEffect } from 'react';
import set from 'lodash/fp/set';
import size from 'lodash/fp/size';
import flow from 'lodash/fp/flow';
import values from 'lodash/fp/values';
import compact from 'lodash/fp/compact';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from 'backend.js';
import { storage } from 'backend.js';
import { ref, getDownloadURL } from 'firebase/storage';

import css from './PublishersSelector.module.scss';

import { GHOST } from 'components/Button/button.constants';

import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Title from 'components/Title/Title';
import Publisher from './Publisher/Publisher';

const initialPublisherSelectorState = { publishers: [], publisherImages: {} };

const transformPublisher = (id, publisher) => {
  return {
    id: id,
    ...publisher,
  };
};

const PublishersSelector = ({ onComplete, onCancel, state = {}, onChange }) => {
  const [publisherSelectorState, setPublisherSelectorState] = useState(
    initialPublisherSelectorState
  );

  const [isFetching, setIsFetching] = useState(false);

  const onSelectPublisher = useCallback(
    (id) => {
      onChange(set(id, !state[id], state));
    },
    [state, onChange]
  );

  useEffect(() => {
    setIsFetching(true);
    getDocs(collection(firestore, 'publishers')).then((querySnapshot) => {
      var publishers = [];
      var publisherImages = {};
      querySnapshot.forEach((doc) => {
        const publisher = transformPublisher(doc.id, doc.data());
        publishers.push(publisher);
        // update the publisher images
        if (publisher.imgPath) {
          const storageRef = ref(storage, publisher.imgPath);
          getDownloadURL(storageRef)
            .then(function (url) {
              publisherImages[publisher.id] = url;
              setPublisherSelectorState({
                publishers: publishers,
                publisherImages: publisherImages,
              });
              setIsFetching(false);
            })
            .catch(function (error) {
              console.error(error);
            });
        } else {
          publisherImages[publisher.id] = '';
          setPublisherSelectorState({
            publishers: publishers,
            publisherImages: publisherImages,
          });
          setIsFetching(false);
        }
      });
    });
  }, []);

  return (
    <div className={css['container']}>
      <div className={css['right-panel']}>
        <Button onClick={onCancel} type={GHOST} label="חזרה" />
      </div>
      <div className={css['content']}>
        <Title>בחרו את ההוצאות לאור אליהן תרצו לשלוח את כתב היד לבחינה:</Title>
        <Loader isFetching={isFetching} label="טוענים הוצאות ספרים">
          <div className={css['publishers-container']}>
            {publisherSelectorState.publishers.map((publisher) => (
              <Publisher
                key={publisher.id}
                isSelected={state[publisher.id]}
                onSelect={() => onSelectPublisher(publisher.id)}
                imgUrl={publisherSelectorState.publisherImages[publisher.id]}
                {...publisher}
              />
            ))}
          </div>
        </Loader>
      </div>
      <div className={css['left-panel']}>
        <Button
          onClick={onComplete}
          label="הבא"
          isDisabled={!flow([values, compact, size])(state)}
        />
      </div>
    </div>
  );
};

export default PublishersSelector;
