import React, {
  createContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import set from 'lodash/fp/set';
import findKey from 'lodash/fp/findKey';
import isUndefined from 'lodash/fp/isUndefined';

import css from './Form.module.scss';

export const FormContext = createContext({});

const Form = ({
  state,
  onChange,
  shouldValidate,
  onValidationStatusChanged,
  children,
}) => {
  const [validationState, setValidationState] = useState({});

  const setFieldValidationState = useCallback(
    (path, isValid) => {
      setValidationState((validationState) =>
        set(path, isValid, validationState)
      );
    },
    [setValidationState]
  );

  useEffect(() => {
    const inValidField = findKey((isValid) => !isValid, validationState);
    if (isUndefined(inValidField)) {
      onValidationStatusChanged(true);
    } else {
      onValidationStatusChanged(false);
    }
  }, [validationState, onValidationStatusChanged]);

  const formProps = useMemo(
    () => ({ state, onChange, shouldValidate, setFieldValidationState }),
    [state, onChange, shouldValidate, setFieldValidationState]
  );

  return (
    <FormContext.Provider value={formProps}>
      <div className={css['form-container']}>{children}</div>
    </FormContext.Provider>
  );
};

export default Form;
