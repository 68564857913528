import React, { useState, useContext, useEffect, useRef } from 'react';
import keys from 'lodash/fp/keys';
import map from 'lodash/fp/map';
import flow from 'lodash/fp/flow';
import reduce from 'lodash/fp/reduce';
import { functions } from 'backend.js';

import { httpsCallable } from 'firebase/functions';

import css from './Payment.module.scss';

import { GHOST } from 'components/Button/button.constants';

import { normalizeSetSubmission } from 'utils/normalize.utils';

import { AuthContext } from 'components/Auth/Auth';
import Button from 'components/Button/Button';
import Login from 'components/Login/Login';
import PaymentSummary from './PaymentSummary/PaymentSummary';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import Loader from 'components/Loader/Loader';

const PaymentDetails = ({ paymentDetails }) => {
  return paymentDetails ? (
    <div className={css['table-container']}>
      <div className={css['table-column']}>
        <div className={css['table-header-label']}>סוג השירות</div>
        {paymentDetails.result.publisherFees.map((publisherFee) => (
          <div className={css['table-data']}>{publisherFee.display}</div>
        ))}
        <div className={css['table-data-highlight']}>
          {paymentDetails.result.suBookFee.display}
        </div>
      </div>
      <div className={css['table-column']}>
        <div className={css['table-header-label']}>מחיר</div>
        {paymentDetails.result.publisherFees.map((publisherFee) => (
          <div className={css['table-data']}>{publisherFee.amount}</div>
        ))}
        <div className={css['table-data-highlight']}>
          {paymentDetails.result.suBookFee.amount}
        </div>
      </div>
    </div>
  ) : null;
};

const Payment = ({ onComplete, onCancel, state = {}, onChange, sendData }) => {
  const [paymentDetails, setPaymentDetails] = useState();
  const [isFetching, setIsFetching] = useState(false);

  const currentState = useRef(null);

  const { currentUser } = useContext(AuthContext);
  const setSubmission = httpsCallable(functions, 'setSubmission');
  const getSubmissionPrice = httpsCallable(functions, 'getSubmissionPrice');

  useEffect(() => {
    currentState.current = state;
  }, [state]);

  useEffect(() => {
    if (currentUser) {
      setIsFetching(true);

      const myParams = normalizeSetSubmission(currentState.current);

      const u = new URLSearchParams(myParams).toString();

      setSubmission({
        userUID: currentUser.uid,
        publisherIDs: keys(state.publisher),
      })
        .then((result) => console.log(result))
        .then(() => {
          console.log('Getting submission price');
          sendData(state.book.book, 'book');
          sendData(state.personalDetails.biography, 'biography');
          getSubmissionPrice({
            bookPath: `uploads/book/${state.book.book.name}_${currentUser.uid}`,
            publisherIDs: keys(state.publisher),
          }).then((result) => {
            setPaymentDetails(result.data);
            setIsFetching(false);
          });
        });
    }
  }, [currentUser]);

  const sum = paymentDetails
    ? flow([
        map('amount'),
        reduce((sum, n) => sum + n, 0),
        (sumOfPublishersFees) =>
          sumOfPublishersFees + paymentDetails.result.suBookFee.amount,
      ])(paymentDetails.result.publisherFees)
    : 0;

  return (
    <div className={css['wizard-page-container']}>
      <div className={css['right-panel']}>
        <Button onClick={onCancel} type={GHOST} label="קודם" />
      </div>
      <div className={css['container']}>
        <Login>
          <Loader isFetching={isFetching} label="אוספים הצעות">
            <PaymentDetails paymentDetails={paymentDetails} />
            <PaymentSummary sum={sum} />
            <PaymentMethods sum={sum} />
          </Loader>
        </Login>
      </div>
      <div className={css['left-panel']}>
        <Button onClick={onComplete} className={css['hidden']} label="סיום" />
      </div>
    </div>
  );
};

export default Payment;
