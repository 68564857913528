import React from 'react';

import css from './PublishersSection.module.scss';

const PublishersSection = () => {
  return (
    <div className={css['container']}>
      <div className={css['title']}>רוצים להופיע באתר?</div>
      <div className={css['label']}>פנו אלינו בכתובת:</div>
      <div className={css['mail']}>info.subook@gmail.com</div>
    </div>
  );
};

export default PublishersSection;
