import React, { useContext } from 'react';

import css from './UserMenu.module.scss';

import { signOut, signInWithGoogle } from 'utils/auth.utils';
import { navigateToPath } from 'utils/history.utils';

import { AuthContext } from 'components/Auth/Auth';

const UserMenu = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <div className={css['container']}>
      {!!currentUser ? (
        <div className={css['container']}>
          <img
            className={css['profile-picture']}
            src={currentUser.photoURL}
            referrerpolicy="no-referrer"
            alt="Profile"
          />
          <div className={css['name-container']}>{currentUser.displayName}</div>
          <div
            className={css['logout-button']}
            onClick={() => navigateToPath('/personal')}
          >
            אזור אישי
          </div>
          <div className={css['logout-button']} onClick={signOut}>
            התנתק
          </div>
        </div>
      ) : (
        <div className={css['logout-button']} onClick={signInWithGoogle}>
          התחבר
        </div>
      )}
    </div>
  );
};

export default UserMenu;
