import React from 'react';

import css from './FAQ.module.scss';

const FAQArray = [
  {
    question: 'מה זה suBook? במה זה שונה? ',
    answer:
      'suBook הינה הפלטפורמה המובילה להגשת כתבי יד להוצאות ספרים בישראל! suBook הוקמה במטרה לאפשר הגשת כתבי יד בקלות, מהירות ונוחות הן למחברים/ות והן להוצאות הספרים. תהליך הוצאות ספר הוא מרגש מאוד (!) אך מדובר בתהליך ארוך ומסורבל, ומטרת suBook היא לשפר את חווית בחירת הוצאות הספרים והגשת כתבי היד בכמה קליקים בודדים ובפלטפורמה אחת בלבד. ',
  },
  {
    question: 'איך זה עובד? מה צריך לעשות?',
    answer: `על מנת להגיש את כתב היד שלך יש ללחוץ על הכפתור ״להגשה״, לאחר מכן תתבקש/י לבחור הוצאה ספרים שאליה תרצה/י להגיש את כתב היד. 
    לאחר שתסמן/י את הוצאות הספרים שאליהם נשלח את כתב היד, תמלא/י בכחמש דקות מספר פרטים אישיים אותם דורשות הוצאות הספרים ותעלה/י את כתבי היד שלך. אנחנו נעשה את כל השאר ותוך דקות כתב היד שלך ינחת בכל הוצאות הספרים שבחרת!
    `,
  },
  {
    question: 'כמה זה עולה? ',
    answer: `שאלת מיליון הדולר! סתם, הרבה פחות. 
    חלק מהוצאות הספרים דורשות עמלה שנעה בין 50 ל-400 ש״ח בעבור בחינת כתבי יד (לקטורה), suBook תציג לך את הנתונים בעבור כל הוצאת ספרים לפני שתקבל/י החלטה לאיזו הוצאה להגיש את כתב היד שלך. בסוף תהליך ההגשה יופיע הסכום לחיוב בתשלום מאובטח. suBook מעבירה סכום זה ישירות להוצאת הספרים. בנוסף, suBook גובה עמלה קטנה בעבור ביצוע התהליך.`,
  },
  {
    question: 'האם כתב היד שלי יתקבל? ',
    answer: `suBook מאוד מקווה בשבילך שכן! עם זאת, suBook לא יכולה להתחייב שכתב היד שלך יתקבל ויצא לאור, משום שההחלטה נתונה לשיקול דעת הוצאת הספרים בלבד. רוב הוצאות הספרים מחזירות תשובה בנוגע לכתב היד תוך 1-6 חודשים. על פי רוב, התשובה תהיה חיובית או שלילית אך ללא נימוק מפורט בנוגע לתשובה. `,
  },
  {
    question: 'לכמה הוצאות כדאי לי להגיש?',
    answer: `בחלק מהמקרים מספיק לשלוח כתב יד להוצאת ספרים אחת בלבד ובמקרים אחרים נדרש לשלוח לעשר הוצאות ספרים ויותר. חשוב לציין שגם אם כתב היד שלך לא התקבל אין זה אומר שהוא לא טוב או לא איכותי, אלא שהוא לא מתאים להוצאת הספרים בנקודת הזמן הנוכחית שבה הוא הוגש. ב-suBook תמיד תוכל/י להמשיך לחפש ולהגיש את כתב היד שלך בקלות ובנוחות עד אשר תמצא הוצאת הספרים שמתאימה לך ושתוציא את ספרך לאור. `,
  },
  {
    question: 'האם המידע שלי מאובטח? ',
    answer: `בהחלט! suBook מקפידה על סטנדרט אבטחה גבוה מאוד. בנוסף, suBook אינה שומרת את כתב היד שלך או פרטיך האישיים הרגישים. כתבי היד נשלחים באופן מידי להוצאות הספרים אשר משתמשות בו לטובת הלקטורה בלבד. `,
  },
];

const FAQ = () => {
  return (
    <div className={css['container']}>
      <div className={css['title']}>שאלות ותשובות</div>
      <div className={css['faqs-container']}>
        {FAQArray.map((qNA) => (
          <div className={css['faq-container']}>
            <div className={css['question']}>{qNA.question}</div>
            <div className={css['answer']}>{qNA.answer}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
