import React, { useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

import css from './PaymentMethods.module.scss';

import { GHOST } from 'components/Button/button.constants';

import history from 'utils/history.utils';

import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';

import bitUrl from 'assets/images/bit.png';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const PaymentMethods = ({ sum }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);

  return (
    <div className={css['container']}>
      <div>
        <Checkbox
          label={
            <>
              קראתי והסכמתי ל
              <Link to="/policy" target="_blank" rel="noopener noreferrer">
                תנאי השימוש
              </Link>
            </>
          }
          value={isAgreed}
          onChange={(e) => setIsAgreed(e.target.checked)}
        />
        <div className={css['label']}>שלם באמצעות:</div>
      </div>
      <div>
        <Button
          label={
            <div className={css['button-content']}>
              <div className={css['button-label']}>תשלום באמצעות ביט</div>
              <img className={css['logo']} src={bitUrl} alt="logo" />
            </div>
          }
          isDisabled={!isAgreed}
          onClick={() => setIsModalOpen(true)}
          className={css['pay-with-bit-button']}
        />
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className={css['modal-container']}>
          <div className={css['modal-title']}>כיצד לשלם באמצעות הביט?</div>
          <div className={css['modal-description']}>
            העבירו {sum}₪ למספר הטלפון 0544447980
          </div>
          <div className={css['modal-important']}>
            * בתיאור ההעברה יש לציין את שם הספר כמו שהוגש למערכת
          </div>
          <div className={css['footer']}>
            <Button
              label="סגור"
              type={GHOST}
              className={css['modal-button']}
              onClick={() => setIsModalOpen(false)}
            />
            <Button
              label="ביצעתי את ההעברה"
              className={css['modal-button']}
              onClick={() => history.push('/personal')}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentMethods;
