import React from 'react';
import classNames from 'classnames/bind';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import css from './RadioButton.module.scss';

import InfoIcon from 'components/InfoIcon/InfoIcon';

const cx = classNames.bind(css);

const RadioButton = ({
  options = [],
  value: selectedValue,
  onChange,
  descriptionAsToolTip = true,
}) => {
  return (
    <div className={css['container']}>
      {options.map(({ label, value, description }) => {
        return (
          <div
            className={cx('option-container', {
              selected: selectedValue === value,
              inline: descriptionAsToolTip,
            })}
            onClick={() => onChange(value)}
          >
            <div className={css['option-label']}>{label}</div>
            {description &&
              (!descriptionAsToolTip ? (
                <div className={css['option-description']}>{description}</div>
              ) : (
                <Tippy content={description}>
                  <span>
                    <InfoIcon className={css['info-icon']} />
                  </span>
                </Tippy>
              ))}
          </div>
        );
      })}
    </div>
  );
};

export default RadioButton;
