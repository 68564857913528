import React from 'react';

import css from './Contact.module.scss';

// const paragraphsArray = [
//   'שלום,',
//   'אנחנו שמחים ונרגשים להודיע ש-suBook - פלטפורמת הגשת כתבי היד הראשונה בישראל - תושק בשבועות הקרובים! הוצאות ספרים רבות וטובות כבר הצטרפו ואנו ממליצים גם לכם להצטרף ולהינות מהגישה של suBook לאלפי כתבי יד חדשים מדי חודש ',
//   'אצלנו ב-suBook מחברים/ות יוכלו לבחור את הוצאת הספרים שהכי מתאימה לכתב היד שלהם ולסגנונם האישי ולהשלים את כל תהליך הגשת כתב היד באופן איכותי ומהיר במיוחד ',
//   `עבור הוצאות הספרים השימוש בפלטפורמה הינו חינמי לחלוטין ואינו כרוך בעבודה או בהשקעת זמן מיותרת. אנו נשלח אליכם במייל כתבי יד ופרטים אישיים של מחבר/ים שיהיו מעוניינים/ות בכך באופן שוטף או בכל דרך אחרת שתבחרו `,
//   `במקרה של גבית עמלה על תהליך הלקטורה, suBook תחייב את המחבר/ים ותעביר אל הוצאת הספרים את הסכום המלא שאותו היא דורשת בהעברה בנקאית או צ'ק`,
//   `להצטרפות השיבו במייל חוזר ואנחנו נוסיף אתכם למאגר הוצאות הספרים של suBook! כלל הוצאות הספרים שיצטרפו לפני ההשקה יקבלו מקום בולט ומרכזי באתר לכל תקופת ההשקה `,
//   `מצפים לראותכם איתנו
//   מחברי/ות ישראל ישמחו להגיש את כתבי היד שלהם להוצאת הספרים שלכם!`,
//   `בברכה,
//   צוות suBook`,
// ];

// const About = () => {
//   return (
//     <div className={css['container']}>
//       <div className={css['content']}>
//         <div className={css['title']}>מי אנחנו?</div>
//         {paragraphsArray.map((paragraph) => (
//           <div className={css['paragraph']}>{paragraph}</div>
//         ))}
//       </div>
//     </div>
//   );
// };

const Contact = () => {
  return (
    <div className={css['container']}>
      <div className={css['title']}>נתקלתם בבעיה?</div>
      <div className={css['title']}>רוצים לשאול שאלה?</div>
      <div className={css['label']}>פנו אלינו במייל:</div>
      <div className={css['mail']}>info.subook@gmail.com</div>
      <div></div>
    </div>
  );
};

export default Contact;
