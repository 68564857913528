import { getAuth } from 'firebase/auth';
import { useCallback } from 'react';
import { firebaseApp, auth, provider } from 'backend.js';

const GetLoginOrSignupHandler = (history, loginOrSignup) => {
  return useCallback(
    async (event) => {
      event.preventDefault();
      const { email = '', password = '' } = event.target.elements;
      if (email === '' || password === '') {
        throw 'invalid email or password';
      }
      const auth = getAuth(firebaseApp);
      try {
        await loginOrSignup(auth, email.value, password.value);
        history.push('/');
      } catch (error) {
        alert(error);
        throw error;
      }
    },
    [history, loginOrSignup]
  );
};

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const signOut = () => auth.signOut();

export { GetLoginOrSignupHandler };
