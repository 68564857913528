import omit from 'lodash/fp/omit';
import keys from 'lodash/fp/keys';
import get from 'lodash/fp/get';

export const normalizeSetSubmission = (state) => {
  const { personalDetails, book, publisher } = state;

  const normalizedBook = omit('book', book);
  const normalizedPersonalDetails = omit('biography', personalDetails);

  return {
    ...normalizedPersonalDetails,
    ...normalizedBook,
    bioUri: `uploads/biography/${get('biography.name', book)}`,
    bookUri: `uploads/book/${get('book.name', book)}`,
    publishers: [keys(publisher)],
    status: 'submitted',
  };
};
