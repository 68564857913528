import './App.css';
import Modal from 'react-modal';

import Routes from './components/Routes/Routes';

Modal.setAppElement('#modal');

function App() {
  return <Routes />;
}

export default App;
