import React from 'react';
import { Link } from 'react-router-dom';

import css from './Home.module.scss';

export const Home = () => {
  return (
    <div className={css['container']}>
      <div className={css['content-container']}>
        <div className={css['content']}>
          <div className={css['description']}>
            הגשת כתבי יד מעולם לא הייתה קלה כל כך
          </div>
          <div className={css['sub-description']}>
            כל מה שנותר לכם לעשות זה להעלות לאתר את כתב היד ולבחור לאילו הוצאות
            ספרים תרצו להגיש, כל השאר יתבצע אוטומטית
          </div>
          <Link className={css['start-wizard-link']} to="/writer-wizard">
            <div className={css['start-wizard-button']}>להגשת כתב יד</div>
          </Link>
        </div>
      </div>
      <div className={css['footer']}>
        <Link className={css['link']} to="/faq">
          שאלות ותשובות
        </Link>
        <Link className={css['link']} to="/contact">
          צור קשר
        </Link>
        <Link className={css['link']} to="/policy">
          תקנון
        </Link>
      </div>
    </div>
  );
};

export default Home;
